// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .


// Entry point for the build script in your package.json
import jQuery from "jquery";
import * as bootstrap from "bootstrap"

require("@fortawesome/fontawesome-free");


// registration_date_of_birth
$(document).ready(function() {
  $('div.part-time-option').hide();
  $('div.full-time-option').hide();
  $("div." + $('#course_option').val().toLowerCase() + "-option").show();
  
  $('#course_option').change(function() {
    switch ($(this).val()) {
      case "Full-Time":
        $("div.full-time-option").show();
        $("div.part-time-option").hide();
        break;
      case "Part-Time":
        $("div.part-time-option").show();
        $("div.full-time-option").hide();
        break;
    }
  });

  // Show extra input if "other" is selected as answer on "How did you learn about...?"
  // Handle first load of page

  if ($('#registration_how_did_you_learn_about').val() === 'other') {
    $('div#howDidYouLearnAboutOther').show();
  } else {
    $('div#howDidYouLearnAboutOther').hide();
  }
  
  // Handle input change
  $('#registration_how_did_you_learn_about').on("change", function() {
    if (this.value === 'other') {
      console.log("will hide");
      $('div#howDidYouLearnAboutOther').show();
    } else {
      $('div#howDidYouLearnAboutOther').hide();
    };
  });

  // Handling for language skill select form
  // Add one more language skill select form and disable the button if no more skills are available
  var addLanguageSkill = function() {
    $("div.language-skill-form").each(function() {
      if (!$(this).is(":visible") && !$(this).find('input.destroy_language_skill').val()) {
        $(this).show();
        return false;
      }
    });
    var allVisible = true;
    $("div.language-skill-form").each(function() {
      allVisible = ($(this).is(":visible") || $(this).find('input.destroy_language_skill').val()) && allVisible;
    });
    if (allVisible) {
      $('#add_language_skill').prop('disabled', true);
    }
  };

  // Initially show only one empty form
  var has_empty_form = false;
  $("div.language-skill-form").each(function() {
    var language_select = $(this).find(".language-select select");
    var skill_select = $(this).find(".language-select select");
    if (!language_select.val() && !skill_select.val()) {
      if (has_empty_form) {
        $(this).hide();
      } else {
        has_empty_form = true;
      }
    }
  });

  // Handle button clicks
  $('#add_language_skill').click(function() {
    addLanguageSkill();
  });

  $('a.remove_language_skill').click(function() {
    var currentSkill = $(this).parent().parent();
    currentSkill.hide();
    currentSkill.find('input.destroy_language_skill').val(true);
  });

  // Handling for attachments form
  // Add one more attachment form and disable the button if no more attachments are available
  var addAttachment = function() {
    $("div.attachment-form").each(function() {
      if (!$(this).is(":visible") && !$(this).find('input.destroy_attachment').val()) {
        $(this).show();
        return false;
      }
    });
    var allVisible = true;
    $("div.attachment-form").each(function() {
      allVisible = ($(this).is(":visible") || $(this).find('input.destroy_attachment').val()) && allVisible;
    });
    if (allVisible) {
      $('#add_attachment').prop('disabled', true);
    }
  };

  // Initially show only one empty form
  has_empty_form = false;
  $("div.attachment-form").each(function() {
    var select = $(this).find("select");
    if (!select.val()) {
      if (has_empty_form) {
        $(this).hide();
      } else {
        has_empty_form = true;
      }
    }
  });

  // Handle button clicks
  $('#add_attachment').click(function() {
    addAttachment();
  });

  // Handle button clicks
  $('a.remove_attachment').click(function() {
    var currentAttachment = $(this).parent().parent().parent();
    console.log(currentAttachment);
    currentAttachment.hide();
    currentAttachment.find('input.destroy_attachment').val(true);
  });
});

